import Img from "react-cool-img";

export default function PropertyListingCard({ header, description, image = null, bgcolour = "from-red-700", textcolour = "white", isImageRight = true, imgSource = '' }) {
    let bgGradientDirn = isImageRight ? 'bg-gradient-to-r' : 'bg-gradient-to-l'; //helps determine the direction the gradient is to be applied to based on prop value indicating position of image
    let bgGradientColour = bgcolour; // `from-${bgcolour}`; //helps create tailwind classname for the background colour that (by default) gradients into transparent if to- colour is supplied
    let leftContent, rightContent;
    // leftContent = isImageRight ? description : <Img />;
    // rightContent = isImageRight ? <Img /> : description;
    console.log(bgGradientDirn, bgGradientColour);
    return (
        // <div className={`${bgGradientDirn} ${bgGradientColour} max-w-7xl p-10 w-screen h-auto mx-auto`}>
        <div className={`${bgGradientDirn} ${bgGradientColour} my-10 max-w-[1440px] p-10 w-screen h-auto mx-auto `}>
            {/* <h1 className="font-semibold text-4xl pb-2 pt-1 drop-shadow-[1.2px_1.2px_1.2px_rgba(255,255,255,0.8)] text-indigo-950 "> {header} </h1> */}
            <div className="">
                {isImageRight &&
                    <div className="grid grid-cols-1 md:grid-cols-2 ">
                        {/* <div id="leftDescriptionBox" className="pl-10 w-8/12 drop-shadow-[1.2px_1.2px_1.2px_rgba(200,200,200,0)] text-gray-900 text-lg hover:drop-shadow-[2.2px_2.2px_2.2px_rgba(150,150,150,0.8)] box-border">
                            {description}
                            </div>
                            
                            <div id="rightImageBox" className="w-7/12 flex justify-end p-4 mr-4  drop-shadow-[3px_3px_3px_rgba(0,0,0,0.8)] hover:drop-shadow-[9px_9px_9px_rgba(0,0,0,0.8)] box-border">
                            <Img src={imgSource} alt="Prop Image" width="50%" />
                            </div> */}

                        <div className="space-y-4 flex flex-col justify-center">
                            <h2 className="font-semibold text-4xl">
                                {header}
                            </h2>
                            <div id="leftDescriptionBox" className="text-gray-900 text-lg">
                                {description}
                            </div>
                        </div>

                        <div id="rightImageBox" className="mx-auto flex  p-4 mr-4 rounded-lg object-cover transform transition hover:scale-105 ease-in-out duration-150">
                            <Img src={imgSource} alt="Prop Image" />
                        </div>
                    </div>

                }
                {!isImageRight &&
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 ">
                            {/* <div id="leftDescriptionBox" className="pl-10 w-8/12 drop-shadow-[1.2px_1.2px_1.2px_rgba(200,200,200,0)] text-gray-900 text-lg hover:drop-shadow-[2.2px_2.2px_2.2px_rgba(150,150,150,0.8)] box-border">
                            {description}
                            </div>
                            
                            <div id="rightImageBox" className="w-7/12 flex justify-end p-4 mr-4  drop-shadow-[3px_3px_3px_rgba(0,0,0,0.8)] hover:drop-shadow-[9px_9px_9px_rgba(0,0,0,0.8)] box-border">
                            <Img src={imgSource} alt="Prop Image" width="50%" />
                            </div> */}
                            <div id="leftImageBox" className="mx-auto flex  p-4 mr-4 rounded-lg object-cover transform transition hover:scale-105 ease-in-out duration-150">
                                <Img src={imgSource} alt="Prop Image" />
                            </div>
                            <div className="space-y-4 flex flex-col justify-center">
                                <h2 className="font-semibold text-4xl">
                                    {header}
                                </h2>
                                <div id="rightDescriptionBox" className="text-gray-900 text-lg">
                                    {description}
                                </div>
                            </div>


                        </div>
                        {/* <div id="leftImageBox " className="w-2/3 flex justify-start p-4 drop-shadow-[3px_3px_3px_rgba(0,0,0,0.8)] hover:drop-shadow-[9px_9px_9px_rgba(0,0,0,0.8)]  box-border">
                            <Img src={imgSource} alt="Prop Image" width="50%" />
                        </div>

                        <div id="rightDescriptionBox" className="w-2/3 drop-shadow-[1.2px_1.2px_1.2px_rgba(200,200,200,0)] pr-10 text-gray-900 text-lg hover:drop-shadow-[2.2px_2.2px_2.2px_rgba(150,150,150,0.8)] box-border">
                            {description}
                        </div> */}
                    </>
                }
            </div>
        </div >
    )
}


// 