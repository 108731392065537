import './App.css';
import Layout from './pages/Layout';
import Home from './pages/Home';
import PropertyListings from './pages/PropertyListings';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import DPNavbar from './modules/HomePage/Components/Navbar';
import DPFooter from './modules/HomePage/Components/Footer';
import Carousel from './modules/HomePage/Components/Carousel';
import GradientBackgroundTextWithImage from './modules/HomePage/Components/GradientBackgroundTextWithImage';

import { Description } from '@headlessui/react';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="listings" element={<PropertyListings />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
