import Carousel from "../modules/HomePage/Components/Carousel";
import GradientBackgroundTextWithImage from "../modules/HomePage/Components/GradientBackgroundTextWithImage";

export default function Home() {
    return (
        <div>
            <Carousel />
            <GradientBackgroundTextWithImage id="contact-section" />
        </div>
    )
}