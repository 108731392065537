import React, { useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react' //Menu, MenuButton, MenuItem, MenuItems 
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import DPLogo from '../../../Assets/DP_Logo.webp'
import DPLogoText from '../../../Assets/DP_Logo_Text.webp'


export default function DPNavbar() {
  const [activeTab, setActiveTab] = useState('Dashboard');

  const handleTabClick = (tabName) => {
    //event.preventDefault();
    console.log("Setting active tab to:");
    setActiveTab(tabName);
    console.log(tabName);
  };

  const getTabClass = (tabName) => (
    activeTab === tabName
      ? 'inline-flex items-center border-b-2 border-red-400 px-1 pt-1 text-sm font-medium text-gray-900'
      : 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
  );
  return (
    <Disclosure as="nav" className="bg-white shadow">
      <div className="mx-auto max-w-7xl">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <Link
                to="/"
                onClick={() => handleTabClick('Dashboard')}
                className={getTabClass('Dashboard')}
              >
                <img
                alt="DP Logo"
                src={DPLogo}
                className="h-12 w-auto"
                />
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {/* Current: "border-red-400 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
              <Link
                to="/"
                onClick={() => handleTabClick('Dashboard')}
                className={getTabClass('Dashboard')}
              >
                Dashboard
              </Link>
              {/* <a
                href="#"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Team
              </a> */}
              <Link
                to="/listings"
                onClick={() => handleTabClick('Projects')}
                className={getTabClass('Projects')}
              >
                Projects
              </Link>
              {/* <a
                href="#"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Calendar
              </a> */}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            

            {/* // Profile dropdown  */}
            <div as="div" className="relative ml-3">
              {/* <div> */}
                {/* <div className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"> */}
                  {/* <span className="absolute -inset-1.5" /> */}
                  {/* <span className="sr-only">Open user menu</span> */}
                  <button className="mt-2 mb-2 text-white font-medium px-4 mr-5 py-2 rounded-xl bg-red-500 
  hover:bg-orange-600 ring-0 ring-red-600 hover:ring-2 hover:ring-inset"
  onClick={() => window.location.href = "/#contact-section"}>
  Contact Us
</button>
                {/* </div> */}
              {/* </div> */}
            </div>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
          <DisclosureButton
            as={Link}
            to="/"
            className={getTabClass('Dashboard')}
            onClick={() => handleTabClick('Dashboard')}
            //className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
          >
            Dashboard
          </DisclosureButton>
          {/* <DisclosureButton
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
          >
            Team
          </DisclosureButton> */}
          <DisclosureButton
            as={Link}
            to="/listings"
            className={getTabClass('Projects')}
            onClick={() => handleTabClick('Projects')}
            //className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
          >
            Projects
          </DisclosureButton>
          {/* <DisclosureButton
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
          >
            Calendar
          </DisclosureButton> */}
        </div>
        {/* <div className="border-t border-gray-200 pb-3 pt-4">
          <div className="flex items-center px-4">
            <div className="flex-shrink-0">
              <img
                alt=""
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                className="h-10 w-10 rounded-full"
              />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">Tom Cook</div>
              <div className="text-sm font-medium text-gray-500">tom@example.com</div>
            </div>
            <button
              type="button"
              className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <BellIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        
        </div> */}
      </DisclosurePanel>
    </Disclosure>
  )
}