//Carousel.jsx

import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
	carousel_img_1,
	carousel_img_2,
	carousel_img_3
} from "../../../Assets"


const Carousel = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3500,

		appendDots: dots => (
			<div>
				<ul style={{
					marginTop: "-40px", padding: "0px"
				}}> {dots} </ul>
			</div>
		),

		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			}
		]
	};

	return (
		<>
			<div className="full-width-carousel pb-0 colourthemed-div-bg">
				<Slider {...settings}>
					<div className="full-width-slide">
						<img src= {carousel_img_1}
// "https://th.bing.com/th/id/R.9b513202377bd333c0304b4542ab7c41?rik=kaItRKI3E6KHgw&pid=ImgRaw&r=0"
							alt="Slide 1"
							className="slide-image" />
					</div>
					<div className="full-width-slide">
						<img src= {carousel_img_2}
// "https://live.staticflickr.com/672/21816855308_73caf847d0.jpg"
							alt="Slide 2"
							className="slide-image" />
					</div>
					<div className="full-width-slide">
						<img src= {carousel_img_3}
// "https://i.pinimg.com/originals/ed/7c/3c/ed7c3cffcf973421dcfce8d9f835cf1c.jpg"
							alt="Slide 3"
							className="slide-image" />
					</div>
				</Slider>
			</div>
		</>
	);
};

export default Carousel;
