// Carousel Images
export const carousel_img_1 = require("./carousel_1.jpeg")
export const carousel_img_2 = require("./carousel_2.jpeg")
export const carousel_img_3 = require("./carousel_3.jpeg")

//Image next to the contact us form
export const founder_img = require("./Founder_Image-nobg.png") //TO DO udhar ka import sahi karna hai


//Listing images
export const trident_hills_img = require("./Listing Pics/Trident Hills.webp")
export const marbella_twin_towers_img = require("./Listing Pics/Marbella Twin Towers.webp")
export const marbella_royce_img = require("./Listing Pics/Marbella Royce.webp")
export const dlf_valley_img = require("./Listing Pics/DLF valley.webp")
