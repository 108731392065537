import './GradientBackgroundTextWithImage.css';
import Img from "react-cool-img";
import Founder_Image from "../../../Assets/Founder_Image-nobg.png";
import CompiledForm from './CompiledForm';

export default function GradientBackgroundTextWithImage() {

    return (
        <div className='box overflow-hidden text-center'>
            <h1 className='pt-3 pb-2 font-bold text-3xl underline text-white'> Get in Touch with a Legacy of well-founded Initiatives </h1>
            <div className = 'p-0 flex justify-evenly h-auto'>
                <div className='w-5/6 pr-5'>
                    <h1 className='italic font-medium text-lg pb-4 pt-2  text-white shadow-sm'>Dhruv Properties, established in 1990, is a trusted real estate partner in Panchkula and the Tricity area. We offer comprehensive services including residential and commercial sales, rentals, investment consulting, and property valuation. Our client-centric approach and local market expertise ensure personalized solutions. With decades of experience, our expert team guides you through buying, selling, or investing in all types of properties. Choose Dhruv Properties for integrity, excellence, and to turn your real estate dreams into reality.</h1> 
                    <h2 className='font-medium pb-2 text-lg text-white'> Please fill the form below and we'll get in touch at the soonest.</h2>
                    <CompiledForm  />
                </div>
                {/* <div style={{overflow:"hidden"}} className='min-w-min p-0 '>
                    <Img /* placeholder ={loading image} error={Error Image} src={Founder_Image}  alt="Founder_Image"  style={{ objectFit: 'cover'}} />
                </div> */}
            </div>
        </div>
    )
}