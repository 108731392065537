import DPNavbar from "../modules/HomePage/Components/Navbar";
import DPFooter from "../modules/HomePage/Components/Footer";
import { Outlet } from "react-router-dom";
export default function Layout ()  {
    return (
        <>
            <DPNavbar />
                <Outlet />
            <DPFooter />
        </>
    )
}
