import PropertyListingCard from "../modules/PropertiesPage/PropertyListingCard";
import TwinTowerPic from "../Assets/Listing Pics/Listing_Marbella-Twin-Tower.webp";
import TridentHillsPic from "../Assets/Listing Pics/TridentHills.webp";
import {
  marbella_royce_img,
  dlf_valley_img,
  trident_hills_img,
  marbella_twin_towers_img
} from "../Assets"

export default function PropertyListings() {
    return (
      <div>
        <PropertyListingCard
          header="Marbella Twin Tower"
          description="Located in Sector 2, Madhya Marg, Marbella Twin Towers is a stunning complex featuring eight towers with 28 floors each. It offers opulent 5 BHK and 4 BHK residences designed with contemporary aesthetics and top-tier amenities. The community is a gated paradise, seamlessly blending natural beauty with urban convenience. With expansive living spaces and abundant natural light, these apartments are crafted for those who seek sophistication and comfort."
          bgcolour="from-white to-blue-400"
          imgSource={marbella_twin_towers_img}
          isImageRight={true}
        />
        
        <PropertyListingCard
          header="Trident Hills - Luxury Living in Panchkula"
          description="Trident Hills, located in Sector 4, Panchkula Extension, offers luxurious apartments starting from ₹2.30 Crore and premium plots at ₹90,000 per sq. yd. This prestigious development features high-quality construction with modern amenities, excellent investment potential, and flexible options for immediate occupancy or custom building. Experience luxury and convenience in Panchkula's most sought-after development. Contact Dhruv Properties to explore your new home at Trident Hills."
          bgcolour="from-white to-blue-800"
          imgSource={trident_hills_img}
          isImageRight={false}
        />
        
        <PropertyListingCard
          header="Marbella Royce"
          description="Situated in Sector 83A, Sahibzada Ajit Singh Nagar, Marbella Royce complements the luxurious lifestyle of its residents with elegant designs and modern facilities. This development is perfect for families looking for spacious homes equipped with all the latest comforts.
          As you explore these magnificent properties, remember:

          'Home is where your story begins.'

          At Marbella Twin Towers and Royce, your story unfolds in a setting of unparalleled luxury and tranquility. Embrace a lifestyle that celebrates both elegance and community.Share "
          bgcolour="from-white to-blue-400"
          imgSource={marbella_royce_img}
          isImageRight={true}
        />
        
        <PropertyListingCard
          header="DLF Valley Gardens"
          description="DLF Valley offers diverse residential options including Valley Flats (3 BHK + Study at ₹1.25 Crore, 2,330 sq. ft.), Valley Gardens (3 BHK Independent Floors at ₹2.10 Crore with members-only club), and Valley Orchards (Under Construction 3 BHK at ₹1.80 Crore, completion 2028). Located just 13 km from Chandigarh, this thriving community of 1,400+ families enjoys serene surroundings and premium amenities. Backed by DLF's 74-year legacy, the project offers flexible payment plans and promises quality living. Explore your dream home at DLF Valley today!"
          bgcolour="from-white to-blue-800"
          imgSource={dlf_valley_img}
          isImageRight={false}
        />
        
        {/* <PropertyListingCard
          header="Aerovista"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa unde enim minima obcaecati at voluptatum voluptate voluptatibus, natus impedit veniam eveniet vitae, voluptatem voluptates excepturi. Nisi hic veritatis placeat. Atque praesentium quam eveniet excepturi qui facilis tenetur beatae nihil eos inventore laboriosam repudiandae alias molestiae error sed sint labore illo modi, quia amet, cum fugit ea quisquam? Unde sapiente optio omnis, quia vel ab corporis quisquam modi, porro expedita aperiam ut atque vitae? Cum libero praesentium consectetur maiores itaque beatae doloremque vel numquam iure? Modi adipisci omnis possimus accusamus vero neque."
          bgcolour="from-blue-500"
          imgSource={TwinTowerPic}
          isImageRight={true}
        />
        
        <PropertyListingCard
          header="Hill View Estate"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa unde enim minima obcaecati at voluptatum voluptate voluptatibus, natus impedit veniam eveniet vitae, voluptatem voluptates excepturi. Nisi hic veritatis placeat. Atque praesentium quam eveniet excepturi qui facilis tenetur beatae nihil eos inventore laboriosam repudiandae alias molestiae error sed sint labore illo modi, quia amet, cum fugit ea quisquam? Unde sapiente optio omnis, quia vel ab corporis quisquam modi, porro expedita aperiam ut atque vitae? Cum libero praesentium consectetur maiores itaque beatae doloremque vel numquam iure? Modi adipisci omnis possimus accusamus vero neque."
          bgcolour="from-yellow-500"
          imgSource={TwinTowerPic}
          isImageRight={false}
        />
        
        <PropertyListingCard
          header="Ecocity Hills"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa unde enim minima obcaecati at voluptatum voluptate voluptatibus, natus impedit veniam eveniet vitae, voluptatem voluptates excepturi. Nisi hic veritatis placeat. Atque praesentium quam eveniet excepturi qui facilis tenetur beatae nihil eos inventore laboriosam repudiandae alias molestiae error sed sint labore illo modi, quia amet, cum fugit ea quisquam? Unde sapiente optio omnis, quia vel ab corporis quisquam modi, porro expedita aperiam ut atque vitae? Cum libero praesentium consectetur maiores itaque beatae doloremque vel numquam iure? Modi adipisci omnis possimus accusamus vero neque."
          bgcolour="from-indigo-500"
          imgSource={TwinTowerPic}
          isImageRight={true}
        /> */}
      </div>
    );
  }
  