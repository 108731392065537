import LabelInputWithIcon from "./FormInput";
import { useState, useRef } from "react";

export default function CompiledForm() {
    //form data dump sheet - https://docs.google.com/spreadsheets/d/1J_wLMv9clDUQDoLFZ4-WaI_WQPGn0rdlaJ9oliFhy6M/edit?gid=0#gid=0
    const [formValues, setFormValues] = useState({
      name: '',
      email: '',
      contact_no: '',
      query: '',
    });
    const formRef = useRef(null);
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
      }));
  };

    const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    fetch("https://script.google.com/macros/s/AKfycbxKPjUKccuUmM1veVEPD6-OsMVsXFOW1dtwbjPUglVASSfnjk_0pNsW7V4iDtjygEO8ig/exec", {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (data.result === 'success') {
        alert('Form submitted successfully!');
        setFormValues({
          name: '',
          email: '',
          contact_no: '',
          query: '',
      });
      formRef.current.reset();
      }
    })
    .catch(error => console.error('Error!', error.message));
    };
    return ( 
      <form method="post" ref={formRef} onSubmit={handleSubmit}> 
        <LabelInputWithIcon 
          labeltext="Name" 
          name="name"  
          placeholder="Your Name" 
          onChange={handleInputChange}
          isRequired={true} 
        />
        <LabelInputWithIcon 
          labeltext="Contact No." 
          name="contact_no"  
          placeholder="(+91)-" 
          isRequired={true} 
          onChange={handleInputChange}
        />
        <LabelInputWithIcon 
          labeltext="Email" 
          name="email"  
          placeholder="you@example.com"
          onChange={handleInputChange}
        />
        <LabelInputWithIcon 
          labeltext="Your Query" 
          name="query" 
          placeholder="Please feel free to ask us anything"
          onChange={handleInputChange}
        />
        <button className="mt-2 mb-2 border-1 border-white text-white font-medium px-2 py-2 rounded-xl hover:bg-orange-600 hover:ring-inset hover:ring-orange-700 hover:ring-2 bg-orange-500" type="submit">
               Submit Form
        </button>
      </form>
    )
  }