/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { EnvelopeIcon, UserIcon, PencilSquareIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'

export default function LabelInputWithIcon({labeltext, name, placeholder, isRequired = false, value, onChange}) {
    let decidedIcon;
    switch (name) {
      case 'email':
        decidedIcon = <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />;
        break;
      case 'name':
        decidedIcon = 
        <UserIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />;
        break;
        case 'contact_no':
        decidedIcon =
        <DevicePhoneMobileIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />;
        break;
      case 'query':
        //decidedIcon = Hero Icon Pen Paper Image
        decidedIcon =
        <PencilSquareIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />;
        break;
      default:
        //decidedIcon = Something fitting for general purpose
    }
    return (
    <div className='bg-gradient-to-r from-slate-300'>
      <label htmlFor={name} className="p-2 block text-xl font-semibold text-left leading-6 text-gray-900">
        {labeltext}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">   
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {decidedIcon}    
        </div>
        <input
          id={name}
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          required = {isRequired}
          onChange={onChange}
          className="block w-full bg-gradient-to-r from-red-100 to-red-50 rounded-md border-0 py-1.5 pl-10 text-gray-900  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  )
}

